<template>
  <div class="condition-form">
    <div class="mb-4">
      <el-button icon="el-icon-plus" class="btn-add-condition" @click="onAddCondition">
        新增條件
      </el-button>
    </div>
    <MemberTagAdvanceSelect
      :modelValue="currentTags"
      :show.sync="show"
      :options="tags"
      @update:modelValue="onUpdateCondition"
    />
    <el-form label-position="top">
      <div v-for="(condition, index) in formData" :key="index">
        <el-form-item>
          <div v-if="index > 0" slot="label">
            <span>或者包含</span>
            <el-tooltip
              :value="showHelp === index"
              placement="right"
              class="condition-help"
              manual
              @click.native="onToggleHelp(index)"
            >
              <div slot="content" class="w-[200px]">
                會員只要滿足單一條件，就能被篩選為目標受眾
              </div>
              <MaterialIcon>help_outline</MaterialIcon>
            </el-tooltip>
          </div>
          <InputConditionGroup
            :titleSuffix="(index+1)+''"
            :includesValue.sync="condition.includes"
            :excludesValue.sync="condition.excludes"
            :showDelete="index !== 0"
            :count="getGroupCount(index)"
            @edit:includes="onEdit(index, 'includes', $event)"
            @edit:excludes="onEdit(index, 'excludes', $event)"
            @delete="onDelete(index)"
          />
        </el-form-item>
      </div>
      <div>
        <el-form-item>
          <div v-if="!showCount" class="flex justify-center">
            <el-button v-if="showSubmit" type="primary" class="btn-submit" @click="onSubmit">確認條件</el-button>
          </div>
          <div v-else>
            <div class="mb-[28px]">
              <p class="text-center text-gray-60 font-medium text-[16px] flex justify-center flex-wrap">
                <span>
                  *提醒: 總計標籤受眾數包含「未綁定Line會員」，
                </span>
                <span>
                  如進行推播，僅會推播給「有綁定Line會員」
                </span>
              </p>
            </div>
            <div class="flex justify-center">
              <el-button class="btn-tag-count">總計標籤受眾數：{{ totalCount }}</el-button>
              <el-button type="primary" class="btn-push-msg" @click="onSendMessage">
                進行推播
              </el-button>
            </div>
          </div>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from 'vue'
import InputConditionGroup from './InputConditionGroup.vue'
import MemberTagAdvanceSelect from '@/components/Select/MemberTagAdvanceSelect.vue'

export default defineComponent({
  name: 'ConditionForm',
  components: {
    InputConditionGroup,
    MemberTagAdvanceSelect,
  },
  props: {
    tags: { type: Array, default: () => [] },
    groupCount: { type: Array, default: () => [] },
    totalCount: { type: Number, default: 0 },
    showCount: { type: Boolean, default: false },
  },
  emits: ['submit', 'message', 'update:showCount'],
  setup (props, { emit }) {
    const formData = ref([
      { includes: [], excludes: [] },
    ])
    const show = ref(false)

    const currentIndex = ref(-1)
    let currentKey = ''
    const currentTags = ref([])

    const onEdit = (index, editKey, tags) => {
      show.value = true
      currentIndex.value = index
      currentKey = editKey
      currentTags.value = tags ? tags.map(i => i.id) : []
    }
    const showSubmit = computed(() => {
      return formData.value.every(i => i.includes.length > 0)
    })

    const onDelete = (index) => {
      formData.value.splice(index, 1)
      showHelp.value = showHelp.value === index ? -1 : showHelp.value
      emit('update:showCount', false)
    }

    const onAddCondition = () => {
      formData.value.push({ includes: [], excludes: [] })
      emit('update:showCount', false)
    }
    const onUpdateCondition = (newTags) => {
      if (!currentKey || currentIndex.value < 0) return
      formData.value[currentIndex.value][currentKey] = props.tags.filter(i => newTags.includes(i.id))
      show.value = false
      emit('update:showCount', false)
    }

    const getGroupCount = (index) => {
      if (!props.showCount) return '-'
      const count = props.groupCount[index]
      return count === undefined ? '-' : count.toString()
    }
    const onSubmit = () => {
      emit('submit', [...formData.value])
    }

    const onSendMessage = () => {
      emit('message', [...formData.value])
    }

    const showHelp = ref(-1)

    const onToggleHelp = (index) => {
      showHelp.value = showHelp.value === index ? -1 : index
    }

    return {
      formData,
      show,
      onEdit,
      currentTags,
      onUpdateCondition,
      onDelete,
      onAddCondition,
      onSubmit,
      onSendMessage,
      showHelp,
      onToggleHelp,
      showSubmit,
      getGroupCount,
    }
  },
})
</script>

<style lang="scss" scoped>
.condition-form {
  :deep(.el-form-item__label){
    @apply pb-4;
  }
  :deep(.condition-help) {
    @apply align-bottom ml-2;
  }
  .btn-add-condition,.btn-tag-count {
    @apply border-[#636363] text-[#767676];

    &:hover,&:focus {
      @apply text-[#555] border-[#555] bg-white;
    }
  }
  .btn-tag-count {
    @apply rounded-r-none border-r-0;
  }
  .btn-tag-count + .btn-push-msg {
    @apply ml-0 border-l-0 rounded-l-none;
  }
  .btn-submit{
    @apply min-w-[126px];
  }
}
</style>
